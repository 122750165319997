<!-- 版本管理 -->
<template>
    <div class="outContainer">
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-input class="each" v-model="searchInfo2.param.name" placeholder="用户姓名"></el-input>
        <el-input class="each" v-model="searchInfo2.param.phone" placeholder="联系电话"></el-input>
        <el-cascader class="each" placeholder="所属商户 / 所属小区"
          clearable
          v-model="unitId2"
          :options="managerUnitOptions2"
          @change="chooseMerchant"
          :props="props2"
          v-if="userInfo2.roleLevel < 5"
        ></el-cascader>
        <el-select class='each' placeholder="所属小区" v-model="searchInfo2.param.unitId" clearable v-if="userInfo2.roleLevel >= 5 && userInfo2.roleLevel < 10">
          <el-option
            v-for="item in unitOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="hanldleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="hanldleSearch('clear')">清空搜索条件</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableContainer">
        <el-table :data="tableData2" style="width: 100%" height="100%">
          <el-table-column align="center" label="昵称" prop="wechatName"></el-table-column>
          <el-table-column align="center" label="姓名" prop="name"></el-table-column>
          <el-table-column align="center" label="联系电话" prop="phone"></el-table-column>
          <el-table-column align="center" label="所属小区" prop="unitName"></el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo2" @callFather="callFather"></pagination>
    </div>
  </template>
  
  <script>
  import pagination from '@/components/Pagination.vue';
  import { getChargingPileUser,getMerchantList,getNeighbourhoodList } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
        searchInfo2:{ // 筛选条件
          pageNo:1,
          pageSize:10,
          total:1,
          param:{
            name:null,
            phone:null,
            merchantId:null,
          },
        },
        tableData2: [], // 表格数据
        managerUnitOptions2:[], // 所属商户、所属小区选项
        unitId2:"",
        unitOptions:[],
        props2: { // 所属商户、小区 联动选择框配置
          value:'id',
          label:'name',
          lazy: true,
          checkStrictly:true,
          lazyLoad (node, resolve) {
            const { level } = node;
            setTimeout(() => {
              let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                  name:null,
                  merchantId:node.value,
                }
              }
              if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
                console.log(node,908)
              }
            }, 1000);
          }
        },
        userInfo2:JSON.parse(localStorage.getItem('userInfo'))
      }
    },
    components: {
      pagination
    },
  
    computed: {},
  
    mounted() {
      this.init();
      this.$nextTick(()=>{
        this.userInfo2.roleLevel < 5 ? this.getMerchantOptions2() : this.getNieghbourList2();
      })
    },
  
    methods: {
      // 获取小区列表
      async getNieghbourList2(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:null,
          }
        }
        await getNeighbourhoodList(data).then(res=>{
          if(res.success){
            this.unitOptions = res.data.list;
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions2(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.managerUnitOptions2 = res.data.list;
            console.log(res.data.list,666)
            this.managerUnitOptions2.forEach(element=>{
              element.children = [];
            })
            
          }else{
            this.managerUnitOptions2 = [];
          }
        })
      },
      // 选择商户、小区
      chooseMerchant(e){
        this.searchInfo2.param.merchantId = e[0] ? e[0] : null;
        this.searchInfo2.param.unitId = e[1] ? e[1] : null;
      },
      // 筛选事件
      hanldleSearch(type){
        this.searchInfo2.pageNo = 1;
        if(type == 'clear'){
          this.searchInfo2.param = {};
          this.unitId2 = [];
        }else{
          this.searchInfo2.param.name = this.searchInfo2.param.name == "" ? null : this.searchInfo2.param.name;
          this.searchInfo2.param.phone = this.searchInfo2.param.phone == "" ? null : this.searchInfo2.param.phone;
        }
        this.init();
      },
      // 初始化获取表格数据
      async init(){
        await getChargingPileUser(this.searchInfo2).then(res=>{
          if(res.success){
            this.tableData2 = res.data.list;
            this.searchInfo2.total = res.data.total;
            this.tableData2.forEach(element => {
              let eachTime = timeChange(element.createTime);
              element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
              for(let key in element){
                if((!element[key] && element[key]!=0) || element[key] == ""){
                  element[key]='--';
                }
              }
            });
          }else{
            this.tableData2 = [];
            this.searchInfo2.total = 0;
          }
        })
      },
      // 分页插件事件
      callFather(parm) {
        this.searchInfo2.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped></style>
  