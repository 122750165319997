<template>
  <section class="outContainer">
    <el-tabs type="border-card" style="height: 100%;">
        <el-tab-pane label="微信充电用户">
            <wechatChargeUser></wechatChargeUser>
        </el-tab-pane>
        <el-tab-pane label="卡充电用户">
            <cardUser></cardUser>
        </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import cardUser from '../cardUser/index.vue';
import wechatChargeUser from './wechatChargeUser.vue';
export default {
  data() {
    return {

    }
  },

  components: {
    cardUser,
    wechatChargeUser
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style scoped lang="scss">
/deep/ .el-tabs__content{
    height: calc(100% - 50px);
}
.el-tab-pane{
    height: 100% !important;
}
</style>
